<template>
  <div ref="commentWrapEle">
    <div class="post-loading" v-show="isLoading">{{isLoading == 'loading' ? '加载中~' : '快去评论吧~'}}</div>
    <vue-comment-list :comments="comments" />
    <vue-page :page="selector.page" :totalPage="totalPage" @page-handler="pageInit" />
  </div>
</template>

<script>
export default {
  name: 'CommentWrap',
  props: {
    articleId: {
      default: null
    }
  },
  provide() {
    return { 
      getComments: this.getComments 
    }
  },
  data() {
    return {
      isLoading: 'loading',
      comments: [],
      selector: {
        objectId: 0,
        page: 1
      },
      pageSize: 100,
      totalPage: 0, //总页数
      totalItemCount: 0 //总条数
    }
  },
  mounted() {
    this.selector.objectId = this.articleId
  },
  watch: {
    articleId(newVal) {
      this.selector.objectId = newVal
    },
    newSelector: {
      handler(newVal, oldVal) {
        // 检测到page变化，但是输出的新旧值的page一样，因此使用序列化和反序列化的方式：JSON.stringify和JSON.parse
        const newVal1 = JSON.parse(newVal);
        const oldVal1 = JSON.parse(oldVal);
        // 评论分页之后，需要滚动到评论的顶部
        if (newVal1.page != oldVal1.page) {
          const top = this.$refs.commentWrapEle.offsetTop - 135;
          window.scrollTo(0, top);
        }
        this.getComments();
      },
      deep: true
    }
  },
  computed: {
    newSelector() {
      return JSON.stringify(this.selector);
    }
  },
  methods: {
    // 点击分页
    pageInit(page) {
      this.selector.page = page;
    },
    // 获取评论列表
    getComments() {
      this.selector.appName = "article"
      this.selector.pageSize = this.pageSize;
      const _this = this;
      this.$ajax.get('/api/comment/list', this.selector, 2, response => {
        if (response.status >= 200 && response.status < 300) {

          _this.comments = response.data.comments;
          if (_this.comments.length == 0)  _this.isLoading = 'nothing'
          else _this.isLoading = null;
          
          _this.totalPage = response.data.pagination?.pageCount || 0;
          _this.selector.page = response.data.pagination?.pageNumber || 1;
          // 总条数
          _this.totalItemCount = response.data.pagination?.totalItemCount || 0;
        } else {
          console.log(response);
          _this.isLoading = null;
        }
      });
    },
  }
}
</script>