<template>
  <div class="detail">
    <div class="post-loading" v-show="isLoading">加载中~</div>
    <div class="detail-top">
      <p class="tag"><span v-for="it in articleObj.tags" :key="it.tagId">{{it.name}}</span></p>
      <div class="title">{{articleObj.subject}}</div>
      <div class="metas">
        <i class="fa fa-clock-o"></i> 发布时间：{{ $formatDate(false, articleObj.updateTime) }}
        <i class="fa fa-comments-o"></i> 评论数量：{{articleObj.commentCount}}
      </div>
      <div class="info quill-editor us-editor">
        <div class="ql-container ql-snow">
          <div class="ql-editor">
            <div v-html="articleObj.content"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="pre-next">
      <div class="pre" v-if="articleObj.pre"><router-link :to="{name: 'ArticleDetail', params: { articleId: articleObj.pre.articleId }}"><span>上一篇: </span>{{articleObj.pre.subject}}</router-link></div>
      <div v-else></div>
      <div class="next" v-if="articleObj.next"><router-link :to="{name: 'ArticleDetail', params: { articleId: articleObj.next.articleId }}"><span>下一篇: </span>{{articleObj.next.subject}}</router-link></div>
      <div v-else></div>
    </div>

    <!-- 发表评论或回复评论 -->
    <div class="comments">
      <div class="title">文章评论</div>
      <vue-comments v-if="$store.state.currentCommentId == 0" @refreshComments="refreshComments" />
    </div>
    
    <!-- 评论部分 -->
    <vue-comment-wrap :articleId="articleId" ref="commentsWrap" />
  </div>
</template>

<script>
import VueComments from '@/components/Comments.vue'
import VueCommentWrap from '@/components/CommentWrap.vue'


export default {
  name: 'ArticleDetail',
  components: {
    VueComments,
    VueCommentWrap
  },
  data() {
    return {
      articleId: 0,
      articleObj: {},
      isLoading: true
    }
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
      this.articleId = to.params.articleId;
      this.getArticleById(to.params.articleId);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log('route',this.$route.params)
    this.articleId = this.$route.params.articleId;
    if (this.articleId) {
      this.getArticleById(this.articleId);
    } else {
      this.getArticleBySlug(this.$route.params.slug);
    }
  },
  methods: {
    // 刷新
    refreshComments() {
      // 刷新评论列表
      this.$refs.commentsWrap.getComments()
    },
    // 显示文章详情信息
    getArticleById(articleId) {
      const _this = this;
      this.$ajax.get('/api/article/' + articleId, '', 2, response => {
        if (response.status >= 200 && response.status < 300) {
          _this.articleObj = response.data;
          _this.isLoading = false
          document.title =_this.articleObj.subject + ' | 前沿技术社区'
        } else {
          console.log(response);
          _this.isLoading = false
        }
      });
    },
    getArticleBySlug(slug) {
      const _this = this;
      slug = slug.replace('.html', '')
      if (slug.length > 9) slug = slug.substr(9)
      this.$ajax.get('/api/article/slug/' + slug, '', 2, response => {
        if (response.status >= 200 && response.status < 300) {
          _this.articleObj = response.data
          _this.articleId = response.data.articleId
          _this.isLoading = false
          document.title =_this.articleObj.subject + ' | 前沿技术社区'
        } else {
          console.log(response);
          _this.isLoading = false
        }
      });
    }
  }
}
</script>

<style lang="scss">
  .detail {
    padding: 20px;
    box-sizing: border-box;
  }

  .pre-next {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 20px 0 -10px;

    span {
      opacity: 0.8;
    }
    div {
      line-height: 2;
    }
  }

  .detail-top {
    margin-top: 2em;

    .tag {
      color: $themeColor;
    }

    .title {
      font-size: 22px;
      line-height: 1.25;
      margin-bottom: 1em;
      font-weight: 400;
    }

    .metas {
      margin: 14px 0;
      color: $lightDefaultColor;
      font-size: 12px;

      i + i {
        margin-left: 12px;
      }
    } 
  }

@media screen and (min-width: 768px) {
  .pre-next {
    flex-direction: row;
    div {
      line-height: initial;
    }
  }
}
</style>
